<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        {{ currency ? $t("edit") : $t("add") }}
      </div>
    </div>
    <div class="card-content">
      <form class="mt-2" @submit.prevent="submit">
        <CustomInput v-model="form.name" :label="$t('name')"></CustomInput>

        <CustomInput v-model="form.code" :label="$t('code')"></CustomInput>

        <b-button
          type="is-primary"
          native-type="submit"
          class="mt-5"
          icon-left="content-save"
          :loading="submitting"
        >
          {{ $t("save") }}
        </b-button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CustomInput from "../../../../components/forms/CustomInput";

export default {
  components: { CustomInput },
  props: {
    currency: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      submitting: false,
      form: {
        name: this.currency?.name,
        code: this.currency?.code,
      },
    };
  },
  methods: {
    ...mapActions(["createCurrency", "updateCurrency"]),
    async submit() {
      this.submitting = true;

      if (this.currency) {
        await this.updateCurrency({
          id: this.currency.id,
          currency: this.form,
        });
      } else {
        await this.createCurrency(this.form);
      }

      this.$parent.close();
      this.$emit("submit");
    },
  },
};
</script>
