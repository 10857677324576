<template>
  <div>
    <b-button icon-left="plus" @click="showModal = true">
      {{ $t("add") }}
    </b-button>
    <b-table
      :data="productTypes"
      :loading="loading"
      class="mt-4"
      default-sort="name"
    >
      <template #empty>
        {{ loading ? $t("loading") : $t("no_matching_results") }}
      </template>

      <b-table-column :label="$t('name')" field="name" sortable>
        <template v-slot="props">
          {{ props.row.name }}
        </template>
      </b-table-column>

      <b-table-column
        :label="$t('retail_value')"
        field="retailValue"
        sortable
        numeric
      >
        <template v-slot="props">
          {{ props.row.retailValue | money }}
        </template>
      </b-table-column>

      <b-table-column numeric>
        <template v-slot="props">
          <b-button
            icon
            size="is-small"
            type="is-text"
            @click="toggleEditModal(props.row)"
          >
            <b-icon icon="pencil" size="is-small"></b-icon>
          </b-button>
        </template>
      </b-table-column>
    </b-table>

    <b-modal v-if="!loading" v-model="showModal" :width="400" scroll="keep">
      <CreateOrEditProductTypeModal
        :product-type="productTypeToEdit"
        @submit="load"
      ></CreateOrEditProductTypeModal>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CreateOrEditProductTypeModal from "./modals/CreateOrEditProductTypeModal";

export default {
  components: { CreateOrEditProductTypeModal },
  data() {
    return {
      loading: true,
      showModal: false,
      productTypes: [],
      productTypeToEdit: null,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["getAllProductTypes"]),
    load() {
      this.productTypeToEdit = null;
      this.loading = true;
      this.getAllProductTypes().then((productTypes) => {
        this.productTypes = productTypes;
        this.loading = false;
      });
    },
    toggleEditModal(productType) {
      this.productTypeToEdit = productType;
      this.showModal = true;
    },
  },
};
</script>
