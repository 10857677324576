<template>
  <div>
    <b-button icon-left="plus" @click="showModal = true">
      {{ $t("add") }}
    </b-button>
    <b-table
      :data="accessoryServiceTypes"
      :loading="loading"
      class="mt-4"
      default-sort="name"
    >
      <template #empty>
        {{ loading ? $t("loading") : $t("no_matching_results") }}
      </template>

      <b-table-column :label="$t('name')" field="name" sortable>
        <template v-slot="props">
          {{ props.row.name }}
        </template>
      </b-table-column>

      <b-table-column numeric>
        <template v-slot="props">
          <b-button
            icon
            size="is-small"
            type="is-text"
            @click="toggleEditModal(props.row)"
          >
            <b-icon icon="pencil" size="is-small"></b-icon>
          </b-button>
        </template>
      </b-table-column>
    </b-table>

    <b-modal v-if="!loading" v-model="showModal" :width="400" scroll="keep">
      <CreateOrEditAccessoryServiceTypeModal
        :accessory-service-type="accessoryServiceTypeToEdit"
        @submit="load"
      ></CreateOrEditAccessoryServiceTypeModal>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CreateOrEditAccessoryServiceTypeModal from "./modals/CreateOrEditAccessoryServiceTypeModal";

export default {
  components: {
    CreateOrEditAccessoryServiceTypeModal,
  },
  data() {
    return {
      loading: true,
      showModal: false,
      accessoryServiceTypeToEdit: null,
    };
  },
  computed: {
    ...mapState({
      accessoryServiceTypes: (state) => state.device.accessoryServiceTypes,
    }),
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["getAccessoryServiceTypes"]),
    load() {
      this.accessoryServiceTypeToEdit = null;
      this.loading = true;
      this.getAccessoryServiceTypes().then(() => {
        this.loading = false;
      });
    },
    toggleEditModal(language) {
      this.accessoryServiceTypeToEdit = language;
      this.showModal = true;
    },
  },
};
</script>
