<template>
  <div>
    <div class="page-header">
      <h1>{{ $t("settings") }}</h1>
    </div>

    <div class="card">
      <div class="card-content pt-3">
        <b-tabs size="is-small">
          <b-tab-item :label="$tc('product', 2)">
            <Products></Products>
          </b-tab-item>

          <b-tab-item :label="$tc('device_status', 2)">
            <DeviceStatuses></DeviceStatuses>
          </b-tab-item>

          <b-tab-item :label="$tc('device_service_types', 2)">
            <DeviceServiceTypes></DeviceServiceTypes>
          </b-tab-item>

          <b-tab-item :label="$tc('accessory', 2)">
            <ProductTypes></ProductTypes>
          </b-tab-item>

          <b-tab-item :label="$tc('accessory_service_types', 2)">
            <AccessoryServiceTypes></AccessoryServiceTypes>
          </b-tab-item>

          <b-tab-item :label="$tc('partner_role', 2)">
            <PartnerRoles></PartnerRoles>
          </b-tab-item>

          <b-tab-item :label="$tc('system_of_measurement', 2)">
            <SystemOfMeasurements></SystemOfMeasurements>
          </b-tab-item>

          <b-tab-item :label="$tc('currency', 2)">
            <Currencies></Currencies>
          </b-tab-item>

          <b-tab-item :label="$tc('language', 2)">
            <Languages></Languages>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import AccessoryServiceTypes from "./partials/AccessoryServiceTypes";
import Currencies from "./partials/Currencies";
import DeviceServiceTypes from "./partials/DeviceServiceTypes";
import DeviceStatuses from "./partials/DeviceStatuses";
import Languages from "./partials/Languages";
import PartnerRoles from "./partials/PartnerRoles";
import Products from "./partials/Products";
import ProductTypes from "./partials/ProductTypes";
import SystemOfMeasurements from "./partials/SystemOfMeasurements";

export default {
  name: "Account",
  components: {
    AccessoryServiceTypes,
    Currencies,
    DeviceServiceTypes,
    DeviceStatuses,
    Languages,
    PartnerRoles,
    ProductTypes,
    Products,
    SystemOfMeasurements,
  },
};
</script>
